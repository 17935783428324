import React from "react"
import { graphql } from 'gatsby'
import loadable from "@loadable/component"
import { Container, Row, Col } from "react-bootstrap"
import LayoutTwo from "../components/layoutTwo"
import Seo from "../components/seo"
import SafeHandsImg from "../images/safe_hands_img.png"
import { PageLinks } from "../common/site/page-static-links";
import FloatingButton from "../components/FloatingButton/FloatingButton"

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"))
const OfficeDetailIntro = loadable(() => import("../components/OfficeDetailIntro/OfficeDetailIntro"))
const GoogleReviews = loadable(() => import("../components/GoogleReviews/GoogleReviews"))
const TileBlock = loadable(() => import("../components/TileBlock/TileBlock"))
const OfficeDetailDesc = loadable(() => import("../components/OfficeDetailDesc/OfficeDetailDesc"))
const StatsModule = loadable(() => import("../components/StatsModule/StatsModule"))
const RecentProperties = loadable(() => import("../components/RecentProperties/OfficeDetailsProperties"))
const SafeHands = loadable(() => import("../components/SafeHands/SafeHands"))
const WhatDoYouNeed = loadable(() => import("../components/WhatDoYouNeed/WhatDoYouNeed"))
const SellLetHome = loadable(() => import("../components/SellLetHome/SellLetHome"))

const OfficeDetail = ({ data }, props) => {
  const PageData = data?.strapiOffice
  console.log(data);
  const officeDetailGlobal = PageData
  const officeDetailProperties = data?.allStrapiProperty?.edges
  let breadcrumData;
  breadcrumData = { parentlabel: PageLinks.contact_label, parentlink: PageLinks.contact, title: PageData.title }
  
  return (
    <LayoutTwo footerClass="footer-cta" popularSearch={"static-detail"} area={PageData.title}>
      <div className="layout-padding-top"></div>

      <BreadcrumbModule {...breadcrumData}
      />

      <OfficeDetailIntro {...PageData} />

      <GoogleReviews />
      {officeDetailGlobal.why_choose && <TileBlock {...officeDetailGlobal.why_choose}
        id={officeDetailGlobal.strapi_id}
        tag="bg-green"
        ptag="office-details"
        imagetransforms={officeDetailGlobal.ggfx_results}
      />}

      <OfficeDetailDesc {...PageData} />

      <Container>
        <Row>
          <Col>
            <div className="border-line"></div>
          </Col>
        </Row>
      </Container>

      {officeDetailGlobal.statistics && <StatsModule {...officeDetailGlobal.statistics} />}

      <RecentProperties data={officeDetailProperties} recent_properties_intro={officeDetailGlobal.recent_properties_intro} />
      <Container>
        <Row>
          <Col>
            <div className="border-line"></div>
          </Col>
        </Row>
      </Container>

      <SafeHands review_slider_intro={officeDetailGlobal.review_slider_intro} Img={SafeHandsImg} ptag="office-details" />

      <Container>
        <Row>
          <Col>
            <div className="border-line"></div>
          </Col>
        </Row>
      </Container>

      {officeDetailGlobal.explore_services && <WhatDoYouNeed {...officeDetailGlobal.explore_services}
        id={officeDetailGlobal.strapi_id}
        pageTag="grey-bg white-bg"
        ptag="office-details"
        imagetransforms={officeDetailGlobal.ggfx_results}
      />}

      {officeDetailGlobal.footer_help_module && <SellLetHome {...officeDetailGlobal.footer_help_module} />}
      <FloatingButton tag="office-detail" phone={PageData.phone} title={PageData.title} email={PageData.email}/>
    </LayoutTwo>
  )
}


export const Head = ({ data }) => {
  const PageData = data?.strapiOffice
  const metaDescription = `Seasoned Estate agents in ${PageData.title} offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.`

  var ldJson = {};
  if (PageData.title === "Loughton") {
    ldJson = {
      "@context": "http://www.schema.org",
      "@type": "RealEstateAgent",
      "name": "Estate & Letting Agents in Loughton",
      "url": "https://www.madisonfox.co.uk/contact/loughton-estate-agents/",
      "image": "https://www.madisonfox.co.uk/icons/icon-384x384.png?v=dad279bf49d72e096f1133e1d7c07123",
      "description": "Seasoned Estate agents in Loughton offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "94 High Road",
        "addressLocality": "Loughton",
        "addressRegion": "Essex",
        "postalCode": "IG10 4HT",
        "addressCountry": "GB"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "51.6441602",
        "longitude": "0.0490331"
      },
      "hasMap" : "https://maps.app.goo.gl/uBbcAY4U9oH8SEyu6",
      "openingHoursSpecification": "Mon-Fri 09:00-18:00 Sa 09:00-17:00",
      "telephone": "020 8508 5271",
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "Sales",
        "telephone": "020 8508 5271"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.5",
        "reviewCount": "72"
      }
  
    };
  } else if (PageData.title === "Chigwell") {
    ldJson = {
      "@context": "http://www.schema.org",
      "@type": "RealEstateAgent",
      "name": "Estate & Letting Agents in Chigwell | Madison Fox",
      "url": "https://www.madisonfox.co.uk/contact/chigwell-estate-agents/",
      "image": "https://www.madisonfox.co.uk/icons/icon-384x384.png?v=dad279bf49d72e096f1133e1d7c07123",
      "description": "Experienced estate agents in Chigwell offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "21 Brook Parade",
        "addressLocality": "Chigwell",
        "addressRegion": "Essex",
        "postalCode": "IG7 6PF",
        "addressCountry": "GB"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "51.6196612",
        "longitude": "0.0727551"
      },
      "hasMap" : "https://maps.app.goo.gl/DqeAEwBEZeXQFWxKA",
      "openingHoursSpecification": "Mon-Fri 09:00-18:00 Sa 09:00-17:00",
      "telephone": "020 8501 2087",
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "Sales",
        "telephone": "020 8501 2087"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.6",
        "reviewCount": "103"
      }
    }
  } else if (PageData.title === "Woodford Green") {
    ldJson = {
      "@context": "http://www.schema.org",
      "@type": "RealEstateAgent",
      "name": "Estate & Letting Agents in Woodford Green | Madison Fox",
      "url": "https://www.madisonfox.co.uk/contact/woodford-green-estate-agents/",
      "image": "https://www.madisonfox.co.uk/icons/icon-384x384.png?v=dad279bf49d72e096f1133e1d7c07123",
      "description": "Our Estate agents in Woodford Green offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "457 High Road",
        "addressLocality": "Woodford Green",
        "addressRegion": "East London",
        "postalCode": "IG8 0XE",
        "addressCountry": "GB"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "51.610152",
        "longitude": "0.0543852"
      },
      "hasMap" : "https://maps.app.goo.gl/pj1q1bdKjboBWxoW9",
      "openingHoursSpecification": "Mon-Fri 09:00-18:00 Sa 09:00-17:00",
      "telephone": "020 8530 6644",
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "Sales",
        "telephone": "020 8530 6644"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.6",
        "reviewCount": "10"
      }
    }
  } else if (PageData.title === "Stratford") {
    ldJson = {
      "@context": "http://www.schema.org",
      "@type": "RealEstateAgent",
      "name": "Estate & Letting Agents in Stratford | Madison Fox",
      "url": "https://www.madisonfox.co.uk/contact/stratford-estate-agents/",
      "image": "https://www.madisonfox.co.uk/icons/icon-384x384.png?v=dad279bf49d72e096f1133e1d7c07123",
      "description": "Madison Fox are an estate agents dealing with sales & lettings in Stratford. Use our instant valuation tool to find out how much your property is worth.",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "11 Burford Rd",
        "addressLocality": "Stratford",
        "addressRegion": "London",
        "postalCode": "E15 2ST",
        "addressCountry": "GB"
      },
      "geo": {
        "@type": "GeoCoordinates",
        "latitude": "51.5371956",
        "longitude": "0.0005453"
      },
      "hasMap" : "https://maps.app.goo.gl/AsuNn6SgWWaRoYrC9",
      "openingHoursSpecification": "Mon-Fri 09:00-18:00 Sa 09:00-17:00",
      "telephone": "020 8555 9933",
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "Sales",
        "telephone": "020 8555 9933"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "3.3",
        "reviewCount": "24"
      }
    }
  }

  return (
    <Seo title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : `Estate & Letting Agents in ${PageData.title}`} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : metaDescription}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      />
    </Seo>
  )
}

export default OfficeDetail


export const query = graphql`
query ($page_id: Int, $title: String) {
    strapiOffice(strapi_id: {eq: $page_id}) {
        email
        strapi_id
        phone
        sales_phone
        sales_email
        lettings_phone
        lettings_email
        title
        heading
        address
        imagetransforms {
          image_Transforms
        }
        image {
          alternativeText
          url
        }
        seo {
          ...SeoFragment
        }
        opening_hours {
          data {
            opening_hours
          }
        }
        about_office {
          data {
            about_office
          }
        }
        accordion_content {
          add {
            title
            content {
              data {
                content
              }
            }
          }
        }
        latitude
        longitude
        opening_hours {
            data {
                opening_hours
            }
        }
        why_choose {
          ...ImageAndContentFragment
        }
        statistics {
          ...StatsFragment
        }
        recent_properties_intro {
          data {
            recent_properties_intro
          }
        }
        review_slider_intro {
          data {
            review_slider_intro
          }
        }
        explore_services {
          ...ServicesTilesFragment
        }
        footer_help_module {
          ...PlainContentFragment
        }
    }

    allStrapiProperty(
    filter: {publish: {eq: true}, address: {address2: {eq: $title}}}
    limit: 10
    sort: {order: DESC, fields: updatedAt}
  ) {
    totalCount
    edges {
      node {
        id
        slug
        crm_id
        strapi_id
        images {
          strapi_json_value {
            url
          }
        }
        status
        price_qualifier
        price
        search_type
        title
        display_address
        ggfx_results {
          ...STRAPI_GGFX_RESULTFragment
        }
      }
    }
  }
  }
`